// src/Review.js
import React from 'react';
import { StarFilled, EllipsisOutlined } from '@ant-design/icons';

const Review = ({ name, date, rating, text, helpfulCount }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow mb-4">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <div className="h-8 w-8 rounded-full bg-gray-300 flex items-center justify-center text-lg font-semibold text-white">
            {name[0]}
          </div>
          <div className="ml-2">
            <h3 className="text-md font-bold">{name}</h3>
            <div className="flex items-center text-sm text-gray-500">
              {[...Array(rating)].map((_, i) => (
                <StarFilled key={i} className="text-yellow-500 mr-1" />
              ))}
              {[...Array(5 - rating)].map((_, i) => (
                <StarFilled key={i} className="text-gray-300 mr-1" />
              ))}
              <span>{date}</span>
            </div>
          </div>
        </div>
        <EllipsisOutlined className="text-gray-500" />
      </div>
      <p className="text-gray-700 mb-2">{text}</p>
      <p className="text-gray-500 text-sm mb-2">
        {helpfulCount} {helpfulCount === 1 ? 'person' : 'people'} found this review helpful
      </p>
      <div className="flex items-center text-gray-500 text-sm">
        <span>Did you find this helpful?</span>
        <button className="ml-2 py-1 px-3 border rounded">Yes</button>
        <button className="ml-2 py-1 px-3 border rounded">No</button>
      </div>
    </div>
  );
};

export default Review;
