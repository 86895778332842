import React, { useState } from "react";
import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Review from "./Review";
import { useNavigate } from "react-router-dom";

const reviews = [
  {
    name: "Raman Patial",
    date: "July 17, 2024",
    rating: 5,
    text: "It is amazing app. It's interface is seamless, hassle free. It is very useful and easy to use with its simple and convenient user interface. It is compatible on all device. It is best source for entertainment and fun.",
    helpfulCount: 1,
  },
  {
    name: "Raja Hoque",
    date: "June 30, 2024",
    rating: 1,
    text: "This is not good app , They some time not paying money when a big amount. Really corrupt people. Be careful. When you win big amount more than 10000 they don't pay money. And they delete you account. I send them many messages they not replied yet. Everyone careful. Not play to this app",
    helpfulCount: 48,
  },
];

const Home = () => {
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  const handleDownload = () => {
    setIsDownloading(true);
    const downloadUrl = "/app-release.apk"; // URL to the APK file in the public directory

    const xhr = new XMLHttpRequest();
    xhr.open("GET", downloadUrl, true);
    xhr.responseType = "blob";

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setDownloadProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      setIsDownloading(false);
      setDownloadProgress(100);
      const url = window.URL.createObjectURL(new Blob([xhr.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "app-release.apk");
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    xhr.onerror = () => {
      setIsDownloading(false);
      setDownloadProgress(0);
      alert("Failed to download APK. Please try again.");
    };

    xhr.send();
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center">
      <header className="w-full flex justify-between items-center p-4 bg-white shadow">
        <div className="flex items-center">
          <img
            src="https://static-00.iconduck.com/assets.00/google-play-icon-512x512-ejjbqcw6.png"
            alt="Google Play"
            className="h-8"
          />
          <span className="text-gray-600 text-lg font-medium ml-2">
            Google Play
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <SearchOutlined className="text-gray-600 text-xl" />
          <QuestionCircleOutlined className="text-gray-600 text-xl" />
          <img
            src={require("./download.png")}
            alt="User Avatar"
            className="h-8 w-8 rounded-full border border-gray-300"
          />
        </div>
      </header>

      <main className="w-full max-w-3xl bg-white p-6 rounded shadow">
        <div className="flex items-center mb-4">
          <img
            src={require("./logo.jpeg")}
            alt="Kalyan Satta Online Market Play"
            className="h-16 w-16 rounded-md"
          />
          <div className="ml-4">
            <h1 className="text-2xl font-bold">
              Kalyan Satta Online Market Play
            </h1>
            <p className="text-green-700 text-sm">Madhur Online Play</p>
          </div>
        </div>

        <div className="mt-6 flex justify-around text-gray-700">
          <div className="flex flex-col items-center">
            <span className="flex items-center text-lg">
              4.5
              <svg
                className="w-5 h-5 text-yellow-500 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
              </svg>
            </span>
            <span className="text-sm text-gray-500">1.16K reviews</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-lg">50K+</span>
            <span className="text-sm text-gray-500">Downloads</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-lg">3+</span>
            <span className="text-sm text-gray-500">Rated for 3+</span>
          </div>
        </div>

        {isDownloading ? (
          <div className="mt-4 w-full bg-gray-200 rounded-lg">
            <div
              className="bg-green-700 text-white py-2 rounded-lg text-sm font-medium text-center"
              style={{ width: `${downloadProgress}%` }}
            >
              {downloadProgress === 100
                ? "Download Complete"
                : `Downloading... ${Math.round(downloadProgress)}%`}
            </div>
          </div>
        ) : (
          <button
            onClick={handleDownload}
            className="mt-4 w-full bg-green-700 text-white py-2 rounded-lg text-sm font-medium"
          >
            Install
          </button>
        )}

        <div className="mt-6 flex justify-around items-center text-green-700">
          <button className="flex items-center space-x-2">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6H6c-1.1 0-2 .9-2 2z"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 3v5h5M3 12h18M3 18h18"
              ></path>
            </svg>
            <span>Share</span>
          </button>
          <button className="flex items-center space-x-2">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <span>Add to wishlist</span>
          </button>
        </div>

        <div className="mt-6">
          <h2 className="text-xl font-bold">About this app</h2>
          <p className="mt-2 text-gray-600">
            Online Market Play App For Entertainment Only. Kalyan is India's
            Online Market App! It provides all Game results including...
          </p>
        </div>

        <div className="mt-6">
          <div className="flex items-center space-x-2 text-gray-600">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 8h10M7 12h4m-6 8h6a2 2 0 002-2v-2a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 002 2h6m-18-4h-6a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v2a2 2 0 01-2 2H9m6 4h-2m-6 0H5m12 0h-6m0 0h-6m0 0h-2m6 0h4"
              />
            </svg>
            <span>This app is available for your device</span>
          </div>

          <div className="mt-4 grid grid-cols-3 gap-4">
            <img
              src="https://play-lh.googleusercontent.com/XceMENrvjt38jaaKn0HxWeA_cmkmFndP8ZdjRDF0FUEPxsFBmJSd5HuCeR9jSAJbAPc=w1052-h592-rw"
              alt="Screenshot 1"
              className="rounded shadow"
            />
            <img
              src="https://play-lh.googleusercontent.com/XceMENrvjt38jaaKn0HxWeA_cmkmFndP8ZdjRDF0FUEPxsFBmJSd5HuCeR9jSAJbAPc=w1052-h592-rw"
              alt="Screenshot 2"
              className="rounded shadow"
            />
            <img
              src="https://play-lh.googleusercontent.com/XceMENrvjt38jaaKn0HxWeA_cmkmFndP8ZdjRDF0FUEPxsFBmJSd5HuCeR9jSAJbAPc=w1052-h592-rw"
              alt="Screenshot 3"
              className="rounded shadow"
            />
          </div>

          <div className="mt-6">
            <h2 className="text-xl font-bold">About this app</h2>
            <p className="mt-2 text-gray-600">
              Online Market Play App For Entertainment Only. Kalyan is India's
              Online Market App! It provides all Game results including...
            </p>
            <p className="mt-2 text-gray-600">• DELHI BAZAR</p>
            <p className="mt-2 text-gray-600">• SHRI GANESH...</p>
          </div>

          <div className="mt-6">
            <h2 className="text-xl font-bold">Updated on</h2>
            <p className="mt-2 text-gray-600">May 25, 2024</p>
          </div>
        </div>

        {/* Data Safety Section */}
        <div className="mt-6">
          <h2 className="text-xl font-bold">Data safety</h2>
          <p className="mt-2 text-gray-600">
            Safety starts with understanding how developers collect and share
            your data. Data privacy and security practices may vary based on
            your use, region, and age. The developer provided this information
            and may update it over time.
          </p>
          <div className="mt-4 p-4 border rounded-lg bg-gray-50">
            <div className="flex items-center space-x-2">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 12h12m-6-6v12"
                ></path>
              </svg>
              <span>No data shared with third parties</span>
            </div>
            <p className="mt-1 text-gray-500">
              <a href="#" className="text-blue-500">
                Learn more
              </a>{" "}
              about how developers declare sharing
            </p>

            <div className="mt-4 flex items-center space-x-2">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 12h12m-6-6v12"
                ></path>
              </svg>
              <span>No data collected</span>
            </div>
            <p className="mt-1 text-gray-500">
              <a href="#" className="text-blue-500">
                Learn more
              </a>{" "}
              about how developers declare collection
            </p>

            <p className="mt-4 text-green-600">
              <a href="#" className="text-green-600">
                See details
              </a>
            </p>
          </div>
        </div>

        {/* Ratings and Reviews Section */}
        <div className="mt-6">
          <h2 className="text-xl font-bold">Ratings and reviews</h2>
          <p className="mt-2 text-gray-600">Ratings and reviews are verified</p>
          <div className="mt-4 flex items-center">
            <h3 className="text-4xl font-bold">4.5</h3>
            <div className="ml-4">
              <div className="flex items-center text-yellow-500">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
                </svg>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
                </svg>
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
                </svg>
                <svg
                  className="w-5 h-5 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
                </svg>
                <svg
                  className="w-5 h-5 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.178c.969 0 1.371 1.24.588 1.81l-3.385 2.46a1 1 0 00-.364 1.118l1.287 3.971c.3.921-.755 1.688-1.539 1.118l-3.385-2.46a1 1 0 00-1.175 0l-3.385 2.46c-.783.57-1.838-.197-1.539-1.118l1.287-3.97a1 1 0 00-.364-1.119l-3.385-2.46c-.783-.57-.381-1.81.588-1.81h4.178a1 1 0 00.95-.69l1.287-3.97z" />
                </svg>
              </div>
              <p className="text-sm text-gray-500 mt-1">1.16K reviews</p>
            </div>
          </div>
          <div className="mt-4">
            <div className="flex items-center text-sm text-gray-600">
              <span className="w-10">5</span>
              <div className="w-full bg-gray-200 rounded h-2 ml-2">
                <div
                  className="bg-green-600 h-2 rounded"
                  style={{ width: "90%" }}
                ></div>
              </div>
            </div>
            <div className="flex items-center text-sm text-gray-600 mt-1">
              <span className="w-10">4</span>
              <div className="w-full bg-gray-200 rounded h-2 ml-2">
                <div
                  className="bg-green-600 h-2 rounded"
                  style={{ width: "5%" }}
                ></div>
              </div>
            </div>
            <div className="flex items-center text-sm text-gray-600 mt-1">
              <span className="w-10">3</span>
              <div className="w-full bg-gray-200 rounded h-2 ml-2">
                <div
                  className="bg-green-600 h-2 rounded"
                  style={{ width: "3%" }}
                ></div>
              </div>
            </div>
            <div className="flex items-center text-sm text-gray-600 mt-1">
              <span className="w-10">2</span>
              <div className="w-full bg-gray-200 rounded h-2 ml-2">
                <div
                  className="bg-green-600 h-2 rounded"
                  style={{ width: "1%" }}
                ></div>
              </div>
            </div>
            <div className="flex items-center text-sm text-gray-600 mt-1">
              <span className="w-10">1</span>
              <div className="w-full bg-gray-200 rounded h-2 ml-2">
                <div
                  className="bg-green-600 h-2 rounded"
                  style={{ width: "1%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        <div className="mt-6">
          {reviews.map((review, index) => (
            <Review
              key={index}
              name={review.name}
              date={review.date}
              rating={review.rating}
              text={review.text}
              helpfulCount={review.helpfulCount}
            />
          ))}
        </div>
      </main>
      <div className="w-full h-[1px] bg-gray-100 mb-2" />
      <footer className="bg-white w-full">
        <a
          href="/privacy-policy"
          className="text-xs block text-center mb-2 text-blue-500 "
        >
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default Home;
